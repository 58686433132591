
































import Vue from 'vue'
import { Prop, Component, Mixins } from 'vue-property-decorator'
import { TOOLBAR_SETTINGS, DEFAULT_TEMPLATE } from '#/plugins/vue-quill-editor'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
// @ts-ignore
import { quillEditor } from 'vue-quill-editor'
import { ModalStore, IModalParams, ModalType } from '~/store/modal'
import { BaseStore } from '#/store/base'
import { updateLicenseOption } from '#/api/license-option'

@Component({ components: { VLoadSpinner, quillEditor } })
export default class ContractsPage extends Vue {
    activeLicenseIndex = 0
    variables = {
        date: '%ORDER_DATE%',
        customer: '%CLIENT_NAME%',
        producer: '%PRODUCER_NAME%',
        beatName: '%BEAT_NAME%',
        licenseName: '%LICENSE_NAME%',
        fileTypes: '%FILE_TYPES%',
        saleUnits: '%SALE_UNITS%',
        saleUnitsWord: '%SALE_UNITS_WORD%',
        streamUnits: '%STREAM_UNITS%',
        streamUnitsWord: '%STREAM_UNITS_WORD%'
    }
    editorOption = { modules: { toolbar: TOOLBAR_SETTINGS } }

    @ModalStore.State('isLoading') isLoading: boolean
    @BaseStore.State('licenseOptionsLight') licenseOptionsLight: ILicenseOptionLight[]

    get activeLicense() {
        return this.licenseOptionsLight[this.activeLicenseIndex]
    }

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void

    saveContracts() {
        Promise.all(
            this.licenseOptionsLight.map(async licenseOption => {
                try {
                    if (licenseOption.isUpdated) await updateLicenseOption(licenseOption)
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
                } catch (err) {
                    this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
                }
            })
        )
    }

    setActiveLicense(clickedLicense: ILicenseOptionLight) {
        const indexOfLicense = this.licenseOptionsLight.findIndex(item => item.id === clickedLicense.id)
        this.activeLicenseIndex = indexOfLicense
    }

    onEditorChange({ html }: { html: string }, license: ILicenseOptionLight) {
        this.activeLicense.contract_html = html
        this.activeLicense.isUpdated = true
    }

    setDefaultContract() {
        this.activeLicense.contract_html = DEFAULT_TEMPLATE
    }
}
